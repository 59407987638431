/* Container */
.manage-countries-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: "PlusJakartaSans", sans-serif;
}

/* Header with title and Add button in the same row */
.header-country {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header-country h2 {
    font-size: 28px;
    font-weight: bold;
}
.add-country-btn{
    padding: 10px 20px;
    background-color: #032f49;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
}
.country-table button {
    background: none; 
    border: none; 
    padding: 0; 
    margin-right: 5px; 
    cursor: pointer; 
    color: #032f49;
    font-size: 18px;
}

.country-table .icon {
    color: #032f49;
    font-size: 20px; 
}

/* Table Styles */
.country-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
}

.country-table th,
.country-table td {
    padding: 8px;
    text-align: center;
    border: 1px solid #ddd;
}

.country-table th {
    background-color: #f4f4f4;
    font-weight: bold;
}
.country-table td {
    font-size: 12px;
}
.country-table tr:hover {
    background-color: #f9f9f9;
}

/* Modal Styles */
.modal-country {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.modal-content-country {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: fadeIn 0.3s ease;
}

.modal-country h3 {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: center;
}
.modal-country p {
    text-align: center;
}
.modal-country input[type="text"] {
    width: 100%;
    padding: 6px;
    margin-top: 8px;
    margin-bottom: 25px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

/* Modal footer button styles */
.modal-footer-country {
    display: flex;
    justify-content: space-between;
}

/* .modal-footer-country .save-btn,
.modal-footer-country .delete-btn {
    padding: 10px 20px;
    margin-left: 10px;
}

.modal-footer-country .cancel-btn {
    background-color:  #032f49;

    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    transition: background-color 0.3s ease;
} */

.btn-country {
    border: none;
    border-radius: 10px; /* Rounded corners */
    cursor: pointer;
    font-family: "PlusJakartaSans";
    font-size: 16px;
    font-weight: 400;
  
}

.save-country{
    padding: 10px 20px;
    background-color: #032f49 !important;
    color: white !important;
    border: none;
    cursor: pointer;
    border-radius: 10px; 
    height:40px;
    width:100px;
    text-align: center;
}

.cancel-country{
    background-color: #6c757d !important; /* Secondary button color */
    padding: 10px 20px;
    color: white !important;
    border: none;
    cursor: pointer;
    border-radius: 10px; 
    height:40px;
    width:100px;
    text-align: center;
}



/* Keyframe animation */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Responsive Styles */
@media (max-width: 600px) {
    .modal-content {
        width: 90%;
    }

    .country-table th, .country-table td {
        padding: 10px;
    }

    .add-country-btn {
        padding: 8px 16px;
    }
}

.disabled-country-btn{
    background-color: #dcdcdc; /* Light gray background */
    color: #a5a5a5;  /* Light gray text */
    cursor: not-allowed;  /* Shows the "not-allowed" cursor */
    opacity: 0.6; /* Reduce opacity */

}