.main-sidebar { 
  background-color: #f6f6f7;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 10px !important;
    max-height: 1076px;
    overflow: auto;
}
.main-sidebar::-webkit-scrollbar {
  width: 8px;
}

.main-sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7A7C82; 
  border-radius: 10px;
}
 
.main-sidebar::-webkit-scrollbar-thumb {
  background: #6b6b6bcc; 
  border-radius: 10px;
}

.main-sidebar::-webkit-scrollbar-thumb:hover {
  background: #313131cc; 
  cursor: pointer;
}
.main-sidebar .sidebar-wrapper{
  max-height: 690px;
}
.main-sidebar ul li{ 
    list-style-type: none;
    font-size: 14px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 6px;
    font-family: sans-serif;
    font-weight: 600;
    color: #4B5563;
    font-size: 10px !important;
}
.main-sidebar ul li div{
    /* display: inline-block; */
}


.main-sidebar ul li div span{
    display: inline-block;
    color: #4B5563
}

.main-sidebar ul li div a{
    padding-top: 5px;
    display: inline-block;
    padding-right: 10px;
    color: #4B5563;
}

.main-sidebar ul li.active a {
    color: #3EBAD6;
  }
  
  .main-sidebar ul li.active div{
    background-color: #EBFAFF; 
  }

  .main-sidebar ul li.user-details span{
    padding-left: 0;
  }
  .main-sidebar ul li div.landingpage-dropdown-content{
    display: none;
    padding: 12px;
    bottom: 40px; 
    left: 160px;
    position: fixed;
    z-index: 10;
  }
  .main-sidebar .landing-logout-btn{
    background: initial;
    margin-top: initial;
    padding-bottom: 5px;
  }
  .main-sidebar ul li.user-details .user-icon{
    padding:0 0 0 0;
  }
  .setting li{
    padding: 10px 0;
  }
  .setting .active li {
    color: #3EBAD6;
  }
  .sidebar-wrapper-help .tooltip-container{
    position: relative;
    cursor: pointer;
    display: inline-block;
  }
  .sidebar-wrapper-help .tooltip-text{
    visibility: hidden;
    width: 150px;
    background: black;
    color: white;
    bottom: 125%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
  
  }
  .sidebar-wrapper-help .tooltip-container:hover .tooltip-text{
    visibility: visible;
    opacity: 1;
  }
  .sidebar-wrapper-notification{
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
 .main-sidebar .sidebar-wrapper-notification .icon-button{
    margin-right: 20px !important;
    background: initial;
  }
  .main-sidebar .icon-button .material-icons{
    font-size: 18px;
  }
  .main-sidebar .notification-dropdown{
    top: initial;
    left: initial;
    position: fixed;
  } 
  .main-sidebar.text-white .notification-dropdown {
    color: #6b7280;
  }
  .main-sidebar .notification-dropdown h6{
    font-size: 18px;
  }
  .main-sidebar .each-notification .username-upload{
    font-size: 12px;
  }
  .main-sidebar .show-all-notifications-btn{
    font-size: 12px;
    width: 100px;
  }
  /* switch btn */
  .switch {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;
  }
  
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #032F49;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 2px;
    background-color: white;
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
 

 