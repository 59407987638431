.viewdashboard-btn {
  color: #fff;
}
.viewdashboard-btn:hover {
  cursor: pointer;
}
.DiscoveryCompanionLogo-wrapper {
  display: contents;
}
.section-wrapper {
  display: flex;
  align-items: center;
}
.view-dashboard-path {
  background: #032f49;
  color: #ffffff;
  padding: 16px 12px;
  border-radius: 6px;
  margin-bottom: 12px;
}
.view-dashboard-path:hover {
  cursor: pointer;
}
.file-upload-wrapper {
  max-height: 200px;
  overflow: auto;
}
.file-upload-wrapper::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

.file-upload-wrapper::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.file-upload-wrapper::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

.file-upload-wrapper::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
}
