/* Container for the whole component */
.user-role-assignment {
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  font-family: "PlusJakartaSans", sans-serif;
  border-radius: 8px;
}

/* Header styling */
.header-userroleassign {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.header-userroleassign h2 {
  font-size: 24px;
  font-weight: bold;
}

/* Button styling */
.user-management button,
.user-role-assignment button,
.manage-organization-btn button {
  padding: 10px 20px;
  background-color: #032f49;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;

}

.user-management button:hover,
.user-role-assignment button:hover,
.manage-organization-btn button:hover {
  background-color: #025277;
}

/* Table styling */
.user-role-table {
  width: 100%;
  border-collapse: collapse;
  /* margin-top: 20px; */
 
}

.user-role-table th,
.user-role-table td {
  padding: 12px;
  /* border: 1px solid #ddd; */
  text-align: center;
}

.user-role-table th {
  background-color: #e1eaf4;
  padding: 16px;
  /* font-weight: bold; */
}
.user-role-table tr:nth-child(even) {
  background-color: #f2f2f2;
}
.user-role-table td {
  font-size: 14px;
}
.userroleassignment input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: not-allowed;
}

/* Action button styling inside the table */

.user-role-table button {
  background: none;
  border: none;
  padding: 0;
  margin-right: 5px;
  cursor: pointer;
  /* color: #032f49; */
  font-size: 18px;
}

.user-role-table .icon {
  color: #032f49;
  font-size: 20px;
}

/* Modal styling */
.modal-userroleassign {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.modal-content-userroleassign {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  position:relative;
}

.modal-userroleassign h3 {
  font-size: 20px;
  font-weight: bold;
}

.modal-userroleassign select {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.role-selection {
  margin: 20px 0;
  text-align: left;
}

.role-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.role-item input[type="checkbox"] {
  margin-right: 10px;
  width: 16px;
  height: 16px;
}

.role-item label {
  font-size: 14px;
}

.modal-footer-userroleassign {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.modal-footer-userroleassign button {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

.modal-footer-userroleassign button:first-child {
  background-color: #032f49;
  color: white;
}

.modal--userroleassign button:last-child {
  background-color: #6c757d;
  color: white;
}

.modal-footer-userroleassign button:hover {
  opacity: 0.8;
}
.selectuseroption {
  width: 100%;
  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: none;
  background-color: #f8f8f8;
  /* appearance: none; */
}

.selectuseroption:focus {
  outline: none;
  border-color: none; /* Optional: Purple border on focus */
  /* box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
}
.role-container {
  background-color: #efefef;
  min-height: auto;
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.role-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px; /* Adjust gap between label and checkbox */
}

.user-role-label {
  min-width: 100px; /* Ensure all labels have the same width */
  text-align: left; /* Align the label text to the left */
}

.icon-checked {
  color: green; /* Change this to match your design */
}
.icon-unchecked {
  color: rgb(225, 43, 7); /* Change this to match your design */
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: auto;
  max-width: 100%;
}
.user-role-table th.fixed-column,
.user-role-table td.fixed-column {
  position: sticky;
  left: 0;
  right:0;
  background-color: #032f49;
  color: white;
  z-index: 1;
}
.user-role-table th.fixed-left {
  position: sticky;
  left: 0;
  z-index: 3; /* Ensures the left column stays above other columns */
  background-color: #032f49;
  color: white;
}
.userrole-table-header{
  position: sticky;
  top: 0;
  z-index: 1;
}

.userrole-table-body {
  overflow-y: auto;
}

.assign-role-btn{
  float:right;
}
/* .iconuser{
  color: #032f49;
}
.user-role-table th.fixed-left,td.fixed-left{
  background-color: #032f49;
  color:white;
} */