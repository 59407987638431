@font-face {
  font-family: "Founders Grotesk";
  src: url("../../common/assets/fonts/FoundersGrotesk-Regular.otf");
}
.landingpage-header {
  background-color: #032f49;
  position: sticky;
  top: 0;
  z-index: 999;
  /* white-space: nowrap; */
}
.landing-header-top {
  display: flex;
  width: 70%;
  margin: auto;
  justify-content: space-between;
  padding-top: 10px;
}
.landing-header-bottom {
  display: flex;
  width: 70%;
  margin: auto;
  justify-content: space-between;
}
.landing-account-info {
  display: flex;
  gap: 12px;
  margin-right: 10px;
  color: grey;
}
.landingpage-user-email {
  color: #4298cd;
}
.landingpage-arrow-down {
  position: relative;
  cursor: pointer;
}
.landingpage-dropdown-content {
  display: none;
  position: absolute;
  border-radius: 12px;
  transform: translateX(-50%);
  width: 190px;
  background-color: #ffffff;
  color: #212529;
  overflow: hidden;
  gap: 5px;
  font-weight: 500;
}
.landingpage-dropdown-content a {
  color: initial;
  display: flex;
  gap: 15px;
}
.landingpage-arrow-down:hover .landingpage-dropdown-content {
  display: block;
  cursor: pointer;
  text-align: justify;
}
.landing-logout-btn {
  display: flex;
  background: #ececec;
  color: #032f49;
  margin-top: 12px;
  gap: 15px;
  padding: 8px 8px 8px 15px;
  align-items: center;
}
.landingpage-dropdown-content span{
  color: #032f49;
  font-size: 14px;
}
.selected-companion {
  color: #fff;
  font-family: "Founders Grotesk";
  font-weight: 500;
  font-size: 30px;
}
.landing-companion-toggle-btn {
  color: #4298cd;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 24px;
}
.landing-companion-toggle-btn li {
  list-style-type: none;
  padding-bottom: 23px;
  font-size: 12px;
  cursor: pointer;
}
.landing-companion-toggle-btn li a {
  padding-bottom: 23px;
  color: #429ACD;
  
}
.landing-companion-toggle-btn a.active {
  /* border-bottom: 3px solid #3ebad6; */
}
.landing-companion-toggle-btn a.active {
  color: #fff;
}
.mode-color-nav {
  color: #fff;
  padding: 0 10px;
}
/* switch */
.slider.light {
  background-color: #fff;
}
.slider.light::before {
  background-color: #032f49;
}
.icon-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 23px;
  height: 23px;
  color: #032f49;
  background: #ffffff;
  border: none;
  outline: none;
  border-radius: 50%;
}

.icon-button:hover {
  cursor: pointer;
}
.icon-button .material-icons {
  font-size: 16px;
}
.icon-button:active {
  background: #cccccc;
}

.icon-button__badge {
  position: absolute;
  top: -8px;
  right: -14px;
  width: 20px;
  font-size: 10px;
  height: 20px;
  background: red;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}
.notification-dropdown {
  position: absolute;
  overflow: hidden;
  top: 100%;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 10px;
  width: 350px;
  box-shadow: 0 4px 8px rgb(0, 0, 0, 0.1);
}
.notification-dropdown h6{
  font-size: 22px;
  text-align: left;
  color: #333333;
  font-family: 'Founders Grotesk';
  font-weight: 600;
}
.initial-notification-userbadge{
  border-radius: 50%;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #032f49;
    color: white;
}
.notification-dropdown .wrapper-div{
  padding: 16px 16px 0px 16px;
}
.each-notification:hover {
  background-color: #ececec;
  cursor: pointer;
}
.each-notification{
  padding: 16px 16px 0px 16px;

}
.each-notification .username-upload{
  font-size: 14px;
  font-weight: 600;
  color: #111827;
  margin-bottom: 5px;
}
.show-all-notifications-btn {
  float: right;
  margin-bottom: 12px;
  margin-right: 12px;
  margin-top: 12px;
  border: none;
  background-color: #4999c9;
  color: white;
  padding: 8px;
  border-radius: 8px;
  width: 130px;
  font-size: 14px;
  font-family: 14px;
}
.notification-details-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e6edf4;
  border-radius: 12px;
  box-shadow: 0 0 5px 1px #a4937e1a;
  padding: 24px;
}
/* .notification-details-wrapper .notification-details{
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e6edf4;
  border-radius: 6px;
  box-shadow: 0 0 5px 1px #a4937e1a;
  padding:0px 24px 24px 24px;
} */
.notification-userbadge {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #032f49;
  color: white;
}
.notification-detail-subwrapper {
  display: flex;
  flex-direction: column;
  margin-left: 40px;
}
.subcontent-wrapper {
  display: flex;
  gap: 5px;
  font-size: 12px;
  padding-top: 8px;
  font-weight: bold;
}
/* .subcontent-wrapper div:not(:last-child)::after{
  content: '';
  position: absolute;
  height: 15px;
  width: 2px;
  background-color: #032f49;
 } */
 .notification-table .notification-download-btn{
  padding: 10px 14px;
  border-radius: 6px;
  background-color: #4999c9;
  border: none;
  color: white;
  font-weight: 600;
  font-size: 12px;
  display: flex;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.notification-table table th{
  background-color: #f9f9fb;
  color: #545878;
  padding: 18px;
  font-weight: 200;
}
.notification-table{
  border: 1px solid #e6edf4;
  border-radius: 8px;
  overflow: hidden;
  color: #475467;
}
.notification-table tbody td img{
  width: 18px;
}
.notification-table td{
  padding: 12px 18px 12px 18px;
  border-bottom: 1px solid #e6edf4;
}
.notification-table td:first-child{
  font-weight: 600;
  width: 40%;
}
.notification-table td:last-child{
  width: 20%;
}