.dropdown-toggle-header {
    background-color: transparent; 
    border: 2px solid #E5E5E5;   
    color: #344054;             
    border-radius: 5px;          
    padding: 8px 16px;           
    font-size: 14px;             
    cursor: pointer;
    border-radius: 9px;             
    transition: all 0.3s ease;  
  }

  .dropdown-toggle-header:hover {
    background-color: transparent; 
    border-color: #007bff;         
    color: #007bff;              
  }



  
  .edit-prompt-buttons{
   background-color: #4999C9;
   border-color: #4999C9;
   width: 145px;
   color: #fff;
   margin-top: 10px;
   border: none;
   height: 40px;
   border-radius: 6px;
  }


  
  

  .drop-down-menu {
    white-space: normal; 
    width: 400px; 
    font-size: 14px;   
    padding: 10px;      
    background-color: #fff !important; 
    border: 1px solid #ccc; 
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
  }
  .drop-down-menu:hover{
    background-color: #f0f0f0; 
    color: #333; 
  }



  .dropdown-item:hover,
.dropdown-item:focus {
  background-color: #ffff !important; 
  color: #333; 
}


  
  
  .drop-down-menu > .dropdown-item {
    white-space: normal; 
   
  }


.header-wrapper{
    height: 30px;
    gap: 20px;
    display: flex; 
    justify-content: end;
    align-items: center; 
    background-color: #fff; 
    margin-top: 10px;
}





.dropdown-content{
    display: none;
    position: absolute;
    border: 1px solid black;
    padding: 8px;
    border-radius: 4px;
    width: 100px;
    text-align: center;
    background-color: #000;
    color: #fff;
    font-weight: 500;
    right: 0;
}
.profile-wrapper:hover .dropdown-content {
    display: block;
    cursor: pointer; 
}
.settings-btn { 
  
   padding: 10px;
    font-size: 14px;
    color: #606770; 
    
}
.highlight-text {
  background-color: #FFEB3B;
  font-weight: bold;
}







 



  
/* 
  scrollbar css */


  

   

  
 