.main-page-container { 
  display: flex; 
  height: calc(100% - 80px);
  width: 100%;
}

.dark-container .intial-message-card-wrapper{
  height: calc(100% - 140px);
}
.page-wrapper { 
  padding: 0 40px; 
  left: 194px;
  top: 50px;
  bottom: 0;
  right: 0; 
  overflow: auto;
  width: 100%;
}
.chat-space-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 13px 0 10px;
  position: relative;
}
.chat-dropdown{
  padding-right: 7px;
  box-shadow: 5px 5px 5px #7090B033;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  margin-top: 25px;
}


.chat-select{
  border: none;
  /* font-weight: 600; */
  border-radius: 10px;
  padding: 5px;
  font-size: 14px;
  cursor: pointer;
}
.chat-select:focus-visible{
  outline: none;
}


.chat-option{
  font-size: 14px;
  font-weight: 500;
  }
  

.coder-toggle-button {
  background-color: #fff;
  border-radius: 12px;
  display: flex;
  margin-top: 8px;
}
.state-advice-dropdown{
  padding:5px;
  border-radius:8px;
  font-size:14px;
  margin-left:4px;
  border: 1px solid #eaf0f6;
  box-shadow: 5px 5px 5px #7090B033;
}
.state-advice-dropdown:focus-visible{
  outline: none;
}
.coder-toggle-button:hover {
  cursor: pointer;
}
.coder-toggle-button span {
  width: 137px;
  height: 39px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 18px 4px 4px 4px;
  color: #1c1813;
  font-weight: 600;
  font-size: 14px;
  border: 1px solid #eaf0f6;
  border-radius: 8px;
}
/* .coder-toggle-button span.active {
  background-color: #4298cd;
  border-radius: 9px;
  color: #fff;
} */
.coder-toggle-button span{
  background-color: #4298cd;
  border-radius: 9px;
  color: #fff;
}
.gptLogo {
  position: absolute;
  right: 0;
  top: calc(50% - 23px);
}
.code-chat {
  width: 100%;
  height: 300px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.disabled-link-codie {
  color: grey;
  pointer-events: none;
}
.code-chat > div p {
  color: #000;
  font-size: 22px;
  font-weight: 500;
  margin: 0;
}
.chatText {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin-top: 6px;
  flex-wrap: wrap;
  gap: 8px;
  text-align: left;
}
.chatText > div {
  width: calc(50% - 8px);
  background-color: #fff;
  border-radius: 12px;
  padding: 7px 20px;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border: 1px solid #3ebad6;
  border-radius: 8px;
}

.chatText > div p {
  margin: 0;
  color: #343541;
  font-weight: 400;
  font-size: 14px;
}
.chatText > div span {
  color: #032f49;
  font-weight: bold;
  font-size: 14px;
}
/* .chatText >div >div {
    display: none;
} */

.chatText > div img {
  display: none;
}
.chatText > div > div img {
  width: 100%;
}
.chatText > div:hover img {
  display: block;
  width: 23px;
  height: 23px;
  position: absolute;
  top: 5px;
  right: 5px;
}
.search-wrapper {
  padding-bottom: 14px;
  margin: 12px 8px 12px 8px;
  position: sticky;
  bottom: 0;
  display: flex;
  background-color: #fff;
  height: 35px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 300;
  justify-content: space-between;
  padding: 7px 20px;
  gap: 5px;
  color: #6c6c6c;
  border: 1px solid #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.search-wrapper input {
  width: 100%;
  border: none;
  padding-left: 14px;
  outline: none;
}
.search-wrapper .input-button {
  top: calc(50% - 21px);
  right: 6px;
  width: 20px;
  height: 20px;
}
.search-wrapper .input-button:hover {
  cursor: pointer;
}

.flex-icons{
  display: flex;
  align-items: center;
  position: absolute;
  right: 7px;
}

.search-wrapper > div img {
  width: 100%;
  height: 100%;
}

/* messages design */
.message-wrapper {
  overflow-y: scroll;
  overflow-x: hidden; 
  height: 100%;
} 
.initial-messages{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}
.intial-message-card-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100% - 75px);
  justify-content: space-between;
}
.message-wrapper::-webkit-scrollbar {
  width: 0; /* Hide scrollbar */
}
.my-input-message {
  display: flex;
  background-color: #fff;
  padding: 10px 15px;
  gap: 10px;
  margin-bottom: 10px;
  border: 1px solid #eaeaea;
  border-radius: 14px;
}
.my-input-logo p {
  width: 35px;
  height: 35px;
  border-radius: 50px;
  background-color: purple;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}
.fade.show.popover.bs-popover-end .popover-body{
  max-height: 400px;
  overflow: auto;
}
.fade.show.popover.bs-popover-end .popover-body::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

.fade.show.popover.bs-popover-end .popover-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #7A7C82; 
  border-radius: 10px;
}
 
.fade.show.popover.bs-popover-end .popover-body::-webkit-scrollbar-thumb {
  background: gray; 
  border-radius: 10px;
}

.fade.show.popover.bs-popover-end .popover-body::-webkit-scrollbar-thumb:hover {
  background: gray; 
  cursor: pointer;
}
.my-input-logo img {
  width: 35px;
  height: 35px;
  margin-top: 8px;
}
.my-input-message-data {
  font-size: 14px;
  width: 100%;
  text-align: justify;
}
.my-input-message-data span {
  font-weight: bolder;
  margin-bottom: 5px;
}
.my-input-message-data p {
  margin: 0px;
}
.like-dislike-icon {
  margin-right: 12px;
}
.selected {
  color: #00f;
}
.like-dislike-icon:hover {
  cursor: pointer;
}

/* stop generating */
.stop-generating {
  border: 1px solid black;
  width: 133px;
  background-color: #032f49;
  color: #fff;
  border-radius: 6px;
  font-size: small;
  display: flex;
  padding: 4px;
  gap: 10px;
  position: absolute;
  top: -35px;
  cursor: pointer;
  margin: 0;
}

.recommendation-modal .modal-content {
  min-height: 400px;
}
.recommendation-modal .loader-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
}
.recommendation-modal .modal-footer,
.recommendation-modal .modal-body {
  padding: 5px;
}
.recommendation-modal .modal-body {
  max-height: 500px;
  overflow: auto;
}
.recommendation-modal > div {
  max-width: 900px;
  padding: 10px;
}
.reference-content {
  margin-bottom: 3px;
}
.references-wrapper {
  border: 1px solid black;
  padding: 14px;
  margin: 15px;
  word-break: break-all;
}
.citations-button {
  color: #1570ef;
  border: 1px solid black;
  border-radius: 2px;
  background-color: #fff;
  margin-left: 4px;
  font-size: 10px;
}
.citations-button:hover {
  color: #fff;
  background-color: #1570ef;
}
.references-color {
  color: green;
}
.references-btn-version1{
  border: none;
  padding: 8px;
  color: white;
  background-color: #7daef3;
  border-radius: 6px;
}
.references-btn-version1:hover{
  background-color: #5f9ef7;
}
.light-sidebar .sidebar-scroll {
  max-height: 600px;
  overflow: auto;
}
.hyperlink-btns{
  border: none;
  background: #fff;
}
.hyperlink-btn-anchor{
  text-decoration: underline;
  cursor: pointer;
}
.hyperlink-btns:hover{
cursor: pointer;
}
.hyperlink-v2{
  border: none;
  background: #fff;
}
.hyperlink-anchor-v2{
  text-decoration: underline;
}
.hyperlink-v2:hover{
cursor: pointer;
}

.parameter-btn{  
  padding: 10px;
  font-size: 14px;
  color: #606770;
}
.parameter-btn:hover{
  cursor: pointer;
}

.parameter-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: flex-start;
  transition: opacity 0.3s ease;
  z-index: 1050; 
}

.parameter-modal.show {
  display: block;
  opacity: 1;
}

.modal-parameter-dialog {
  position: relative;
  margin-top: 5%; 
  width: 80%; 
  max-width: 600px; 
  margin-left: auto;
  margin-right: auto
}

.modal-parameter-content {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.modal-parameter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #ddd;
}

.modal-parameter-title {
  margin: 0;
  font-size: 1.25rem;
  font-weight: bold;
}

.parameter-close-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #333;
  cursor: pointer;
  padding: 0;
}

.modal-parameter-body {
  padding: 5px 15px 20px;
}

.modal-parameter-body h1 {
  font-size: 2rem;
  margin-bottom: 10px;
}
.parameter-field-section{
  /* display: flex; */
  justify-content: space-between;
  align-items: center;
  margin: 5px 10px 10px;
  padding: 10px;
}

.parameter-field-section:hover{
  background-color: #F9FAFB;
  border-radius: 10px;
}

.parameter-top-input-field-section{
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin-right: 10px; */
  border-radius: 5px;
  padding: 10px;
}

.parameter-top-input-field-section:hover{
  /* background-color: #F9FAFB; */
}
.parameter-top-input-field-section label{
  font-weight: 500;
  font-family: 'Founders Grotesk';
  font-size: 15px;
  color: #101828;
}
.parameter-top-input-field-section input{
  outline: none;
  border: none;
  font-size: 14px;
  background-color: inherit;
}

.parameter-top-input-field-section :hover{
  /* background-color: #F9FAFB; */
}


.parameter-apply-btn-section{
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 20px;
}

.parameter-apply-btn{
  background-color: #4298cd;
  border-radius: 9px;
  color: #fff;
  padding: 6px 20px;
  border: none;
  outline: none;
}

.parameter-reset-btn{
  background-color: #4298cd;
  border-radius: 9px;
  color: #fff;
  padding: 6px 20px;
  border: none;
  outline: none;
  margin-right: 15px;
}

.input-parameter-top-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
}

.parameter_number::-webkit-outer-spin-button,
.parameter_number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.parameter_number[type=number] {
  -moz-appearance: textfield;
  width: 90px;
  height: 30px;
  margin-right: 20px;
  border: 1px solid #101828;
  border-radius: 5px;
  padding: 5px 7px;
}

.parameter_number[type=number]:focus-visible{
  outline: none;
}

.parameter-input-modal{
  width: 100%;
  height: 300px;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 40px;
  border: 1px solid #E1E1E1;
  background-color: #fff;
  border-radius: 10px;
}

.parameter-input-modal.block{
  padding-bottom: 10px;
}

.parameter-input-modal input{
  padding: 0px;
}

.parameter-add-input-modal{
  position: absolute;
  bottom: 0;
  margin-bottom: 40px;
  display: inline;
  justify-content: end;
  align-items: center;
  background-color: transparent;
  border-radius: 10px;
  cursor: pointer;
  color: #4999C9;
  font-weight: 500;
  font-size: 14px;
}


.parameter-add-input-modal p{
  margin: 0px;
  padding: 0px 10px;
  display: inline;
}
.uncollapse-input{
  display: flex;
  justify-content: end;
  align-items: center;
  cursor: pointer;
  color: #4999C9;
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
  margin-top: -10px;
}

.parameter-range-silder{
  cursor: pointer;
  transition: ease-in 0.1s
}

.parameter-range-silder[type=range]{
  height: 5px;
  width: 100%;
}

.parameter-tooltip {
  position: relative;
  cursor: pointer; 
  display: flex;
  justify-content: center;
}
.parameter-tooltip:hover::after {
  content: attr(data-title);
  position: absolute;
  left: 200%; 
  top: 100%;
  transform: translateX(-20%); 
  background-color: #575757;
  color: #fff;
  padding: 5px 10px;
  max-width: 500px; 
  width: 400px; 
  word-wrap: break-word; 
  overflow-wrap: break-word;
  border-radius: 4px;
  font-size: 13px;
  white-space: pre-wrap; 
  visibility: visible;
  z-index: 10;
}


.parameter-tooltip:hover::after {
  opacity: 1; 
  visibility: visible;
}


.info_icon{
  display: flex;
  align-items: center;
  padding-left: 5px;
  cursor: pointer;
  height: 16px;
}


.parameter-label-section{
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-modal {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal-content-text {
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.close-btn {
  margin-top: 10px;
  width: 100px;
  font-size: 12px;
  border-radius: 5px;
  height: 30px; 
  background-color: #344054;
   color: white;
  border: none;
  cursor: pointer;
}

.transcript-text{
  color:#333333;
  font-weight:bold;
  width:10px;
  font-size:25px;
  font-family:"Founders Grotesk"
}
.modal-flex-values{
  display: flex;
  align-items: center;
  justify-content: center;
 
}
.flex-conatiner-text{
  display: flex;
  align-items: center;
  
}

.close-icon{
  height: 10px;
  width: 10px ;
  cursor: pointer;

}
.mic-icon{
  height: 40px;
  width: 40px;
}

.flex-text-and-icon{
  display: flex;
  align-items: center;
 
}
.vertical-line{
  color:#4999C9;
  font-weight:"bold";
   font-size:26px
}

.voice-text{
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
}

.audio-wave {
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8e8e8;
  padding: 30px;
  overflow: hidden;
  position: relative;
  gap: 4px; 
}

.segment {
  background-color: #555;
  animation: segment-bounce 1.5s infinite ease-in-out;
  animation-delay: calc(var(--index) * 0.1s);
}


.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
}


.wave {
  width: 6px; 
  border-radius: 2px;
  transform-origin: bottom center;
}


.wave:nth-child(10),
.wave:nth-child(15),
.wave:nth-child(20),
.wave:nth-child(25) {
  height: 12px;
}
.wave:nth-child(11),
.wave:nth-child(19),
.wave:nth-child(27) {
  height: 16px;
}
.wave:nth-child(13),
.wave:nth-child(22) {
  height: 28px;
}
.wave:nth-child(14),
.wave:nth-child(23),
.wave:nth-child(30) {
  height: 36px;
}
.wave:nth-child(16),
.wave:nth-child(18) {
  height: 44px;
}
.wave:nth-child(17),
.wave:nth-child(24) {
  height: 50px;
}

@keyframes segment-bounce {
  0%, 100% {
    transform: scaleY(0.6);
  }
  50% {
    transform: scaleY(1);
  }
}

@media screen and (max-width: 730px) {
  .chat-space-wrapper {
    display: block;
    text-align: center;
    position: initial;
  }
  .page-wrapper {
    left: 0;
  }
  .coder-toggle-button {
    display: flex;
    justify-content: center;
    margin: auto;
    max-width: fit-content;
  }
  .gptLogo {
    position: initial;
  }
  .main-page-container {
    margin-left: 0px;
  }
  .chatText > div {
    width: 100%;
  }
}
