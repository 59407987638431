.header-wrapper{
    height: 30px;
    display: flex; 
    justify-content: end;
    align-items: center; 
    background-color: #fff; 
    margin-top: 10px;
}
.userEmail {
    display: flex;
    align-items: center;
    gap: 12px;
    
}
.userEmail span {
    font-size: 12px;
}
.logo-pallas {
    width: 133px;
    display: flex;
    align-items: center;
}
.logo-pallas img {
    width: 100%;
}
.profile-wrapper{
    position: relative;
    cursor: pointer;
}
.dropdown-content{
    display: none;
    position: absolute;
    border: 1px solid black;
    padding: 8px;
    border-radius: 4px;
    width: 100px;
    text-align: center;
    background-color: #000;
    color: #fff;
    font-weight: 500;
    right: 0;
}
.profile-wrapper:hover .dropdown-content {
    display: block;
    cursor: pointer; 
}
.settings-btn { 
    padding: 10px;
    font-size: 14px;
    color: #606770;
    border-radius: 6px;
}
.settings-btn:hover{
    cursor: pointer;
}
.logout-btn {
    display: flex;
    gap: 10px;
    padding-top: 3px;
}

.toggle-sidebar {
    display: none;
}
.toggle-sidebar:hover{
    cursor: pointer;
}
 
 .archived-chat-wrapper{
    display: flex; 
    gap: 25px;  
 }
 .modal-body.archived-chats{
    max-height: 400px;
    overflow: auto;
 }
 .archive-color{
    color:rgb(69 89 164);
 }
 .archive-delete-btns:hover{
cursor: pointer;
 }
 .back-btn-chat{
    cursor: pointer;
    margin-left: 50px;
 }
@media screen and (max-width: 730px) {
    .toggle-sidebar {
        display: block;
        position: absolute;
        left: 10px;
    }
  }
  .archivedChatIcon {
    display: flex;
  }
  .archived-chat-wrapper .archive-delete-btns{
    margin-left: auto;
  }
/* 
  scrollbar css */

  .modal-body.archived-chats::-webkit-scrollbar {
    width: 7px;
    height: 3px;
}
  
.modal-body.archived-chats::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #7A7C82; 
    border-radius: 8px;
  }
   
  .modal-body.archived-chats::-webkit-scrollbar-thumb {
    background: gray; 
    border-radius: 10px;
  }
  
  .modal-body.archived-chats::-webkit-scrollbar-thumb:hover {
    background: rgb(179, 177, 177); 
    cursor: pointer;
  }