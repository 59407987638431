.usermangement {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Ensures columns have uniform widths */
    max-height: 600px;
  }
  
  .usermangement thead th {
    position: sticky; /* Keeps the header fixed */
    top: 0;
    background-color: #f0f0f0; /* Header background */
  }
  
  .usermangement td,
  .usermangement th {
    text-align: center;
    border: 1px solid #ddd; /* Adds borders */
    padding: 8px;
  }
  