/* Module Management Container */
.module-management-container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Header section with title on the left and button on the right */
.header-section-module {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.title-left-module {
    font-size: 24px;
    margin: 0;
    text-align: left;
}

/* Add Module button aligned to the right */
.add-module-btn {
        display: inline-flex; /* Use flexbox to center the content */
        justify-content: center; /* Horizontally center the text */
        align-items: center; /* Vertically center the text */
        background-color: #032f49 !important;
        color: white !important;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        font-family: "PlusJakartaSans";
        font-size: 16px;
        font-weight: 400;
        height: 40px;
        width: 140px;
        text-align: center; /* Ensures text alignment within the button */
}

/* Center the table */
.module-table {
    width: 100%;
    /* max-width: 1000px; */
    margin: 0 auto;
    border-collapse: collapse;
}

.module-table th, .module-table td {
    padding: 10px;
    border: 1px solid #ddd;
    font-family: "PlusJakartaSans";
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}

.module-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}
.module-table td{
    padding:2px !important;
    vertical-align: middle !important;

}

/* Set fixed width for the action column */
.module-table td:last-child {
    width: 150px; /* Adjust this width as needed */
}

/* Styling for the Edit and Delete buttons */

/* Add some space between the table and the buttons */
.module-table td > button {
    margin-right: 5px;
}
 
.module-table .icon {
    color: #032f49;
    font-size: 20px; 
}
.module-table button {
    background:none; 
    border: none; 
    padding: 0; 
    margin-right: 5px; 
    cursor: pointer; 
    color: #032f49;
    font-size: 18px;
    text-align:center;
}