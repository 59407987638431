/* Role Management Container */
.role-management-container {
    
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Header section with title on the left and button on the right */
.header-section-role {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.title-left-role {
    font-size: 24px;
    margin: 0;
    text-align: left;
}

/* Add Role button aligned to the right */
.add-role-btn {
    display: inline-flex; /* Use flexbox to center the content */
    justify-content: center; /* Horizontally center the text */
    align-items: center; /* Vertically center the text */
    background-color: #032f49 !important;
    color: white !important;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-family: "PlusJakartaSans";
    font-size: 16px;
    font-weight: 400;
    height: 40px;
    width: 120px;
    text-align: center; /* Ensures text alignment within the button */
}

/* Center the table */
.role-table {
    width: 100%;
    /* max-width: 1000px; */
    margin: 0 auto;
    border-collapse: collapse;
}

.role-table th, .role-table td {
 
    border: 1px solid #ddd;
    font-family: "PlusJakartaSans";
    font-size: 12px;
    font-weight: 400;
    vertical-align: middle!important;
    text-align: center;
}

.role-table th {
    padding:10px;
    background-color: #f2f2f2;
    font-weight: bold;
   
}
.role-table td{
    padding:2px !important;
    vertical-align: middle !important;

}
/* Set fixed width for the action column */
.role-table td:last-child {
    width: 160px; /* Adjust this width as needed */
}

/* Styling for the Edit and Delete buttons */


/* Flexbox for aligning the buttons within table cells */
 
.role-table .icon {
    color: #032f49;
    font-size: 20px; 
}
.role-table button {
    background:none; 
    border: none; 
    padding: 0; 
    margin-right: 5px; 
    cursor: pointer; 
    color: #032f49;
    font-size: 18px;
    text-align:center;
}