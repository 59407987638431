.role-module-permissions-container {
    margin-top:40px;
  }
  
  .permissions-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .form-control-rolemodule {
    width: 380px;
    padding: 8px;
  }
  
  .btn-save-permission {
    background-color: #032f49;
    color: white;
    border: none;
    padding: 10px 15px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
  }
  
 
  
  .role-permissions-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .role-permissions-table th,
  .role-permissions-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
  }
  
  .role-permissions-table th {
    background-color: #f4f4f4;
  }
  