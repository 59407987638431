/* src/components/Modal/Modal.css */

.modal-overlay-role {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

.modal-content-common-role {
    background-color: #ffffff; /* Modal background color */
    padding: 20px;
    border-radius: 20px !important; 
    box-shadow: 0 2px 10px rgba(4, 4, 4, 0.2); /* Optional shadow effect */
    min-width: 300px; /* Full width of overlay */
    min-height: 200px; /* Full height of overlay */
    box-sizing: border-box; /* Include padding and border in width and height */
  
}

.modal-title-role {
    margin-top: 0; /* Remove top margin for title */
    text-align:center;
    margin-bottom: 10px;
}

.modal-lable-role {
    display: block; /* Ensure label takes full width */
    font-family: "PlusJakartaSans";
    font-size: 16px;
    font-weight:600;
    text-align: Left;

}

.modal-input-role {
    width: 100%; /* Full width input */
    font-family: "PlusJakartaSans";
    font-size: 14px;
    font-weight:400;
    padding: 4px; /* Padding inside input */
    border: 1px solid #ccc; /* Border around input */
    border-radius: 4px; /* Rounded corners for input */
    margin-bottom: 20px; /* Space below input */
}

.modal-actions-role {
    display: flex;
    justify-content:space-between; /* Align buttons to the right */
}

.btn-role {
    border: none;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    font-family: "PlusJakartaSans";
    font-size: 16px;
    font-weight: 400;
  
}

.btn-primary-save-role {
    padding: 10px 20px;
    background-color: #032f49 !important;
    color: white !important;
    border: none;
    cursor: pointer;
    border-radius: 8px; 
    height:40px;
    width:100px;
    text-align: center;
}

.btn-secondary-cancel-role {
    background-color: #6c757d !important; /* Secondary button color */
    padding: 10px 20px;
    color: white !important;
    border: none;
    cursor: pointer;
    border-radius: 8px; 
    height:40px;
    width:100px;
    text-align: center;
}

body.modal-open {
    overflow: hidden; /* Prevent background scroll when modal is open */
}    